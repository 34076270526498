import React from 'react';
import Layout from '../Layout';
import config from '../../config';
import localization from '../../localization';
import * as style from './index.module.scss';

interface Props {
    language: string;
}

const SignOut = ({ language }: Props) => {
    React.useEffect(() => {
        localStorage.removeItem('isSignedIn');
        window.location.replace(`${config.apiBaseUrl}/account/signout`);
    }, []);

    return (
        <>
            <Layout>
                <div className={style.signOutPage}>
                    <h2>{localization[language].app.labelSigningOut}</h2>
                </div>
            </Layout>
        </>
    );
};

export default SignOut;
